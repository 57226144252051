<template>
  <div>
    <section id="dashboard-ecommerce">
      <b-row class="match-height">
        <b-col
          xl="4"
          md="6"
        >
          <ecommerce-medal :data="details" />
        </b-col>
        <b-col
          xl="8"
          md="6"
        >
          <ecommerce-statistics :data="statisticsItems" />
        </b-col>
      </b-row>

      <b-row class="match-height">
        <div class="col-12">
          <div class="card">
            <div class="card-header border-bottom">
              <h4 class="card-title">
                Recent transactions
              </h4>
            </div>
            <div class="card-datatable">
              <table class="datatables-ajax table">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Name</th>
                    <th>Reference</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Desc</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>SN</th>
                    <th>Name</th>
                    <th>Reference</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Desc</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </tfoot>
                <tbody>
                  <tr
                    v-for="(transaction, index) in transactions"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ transaction.beneficiary }}</td>
                    <td>{{ transaction.reference }}</td>
                    <td>₦{{ transaction.amount_formatted }}</td>
                    <td :class="transaction.type === 'debit' ? 'text-danger' : 'text-success'">
                      {{ transaction.type }}
                    </td>
                    <td>{{ transaction.sub_type }}</td>
                    <td>{{ transaction.created_at_formatted }}</td>
                    <td :class="transaction.status === 'failed' ? 'text-danger' : transaction.status === 'pending' ? 'text-warning' : 'text-success'">
                      {{ transaction.status }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <nav aria-label="Page navigation">
            <ul class="pagination">
              <li :class="link.active ? 'page-item active' : 'page-item'" v-for="link in pageMeta.links" :key="link.id">
                <a class="page-link" href="javascript:;" @click="paginate(link.url)" v-html="link.label"></a>
              </li>
            </ul>
          </nav>            
        </div>
      </b-row>
    </section>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'

export default {
  components: {
    BRow,
    BCol,

    EcommerceMedal,
    EcommerceStatistics,
  },
  data() {
    return {
      data: {},
      links: {},
      pageMeta: {},
      url: '',
      transactions: [],
      settings: {},
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '0',
          subtitle: 'Courses',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UsersIcon',
          color: 'light-info',
          title: '0',
          subtitle: 'Students',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UsersIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'Tutors',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '₦0.00',
          subtitle: 'Revenue',
          customClass: '',
        },
      ],
      details: {
        name: '',
        saleToday: '0.00',
      },
    }
  },
  created() {
    this.url = this.$url+'/transactions'
    this.getTransactions()
    this.getSettings()
  },
  methods: {
    paginate(url) {
      this.url = url
      this.getTransactions()
    },    
    getTransactions() {
      this.$http.get(this.url)
        .then(response => {
          this.transactions = response.data.data.data
          this.links = response.data.data.links
          this.pageMeta = response.data.data.meta
        })
    },
    getSettings() {
      this.$http.get(`${this.$url}/settings`)
        .then(response => {
          this.settings = response.data.data
          this.statisticsItems[0].title = this.settings.sales_count || 0
          this.statisticsItems[1].title = this.settings.customer_count || 0
          this.statisticsItems[2].title = this.settings.product_count || 0
          this.statisticsItems[3].title = this.settings.revenue || 0
          this.details.saleToday = this.settings.sales || 0
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
